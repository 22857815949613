import request from '@/utils/request'

// 专业列表 侧边选择
export const professionalListAPI = (params) => {
  return request({
    url: '/domainList',
    method: 'GET',
    params
  })
}

// 专业列表 表格数据
export const professionalListPagingAPI = (params) => {
  return request({
    url: '/domainallList',
    method: 'GET',
    params
  })
}

// 层次列表
export const layerListAPI = (params) => {
  return request({
    url: '/domainlevelList',
    method: 'GET',
    params
  })
}

// 院系选择列表
export const listOfSelectionOfHospitalsAPI = () => {
  return request({
    url: '/collegeallList',
    method: 'GET'
  })
}

// 专业选择列表
export const professionalSelectionListAPI = (params) => {
  return request({
    url: '/deptmajorList',
    method: 'GET',
    params
  })
}

// // 层次选择列表接口
// export const listOfHierarchicalListingAPI = () => {
//   return request({
//     url: '/levelallList',
//     method: 'GET'
//   })
// }

// 添加专业
export const addProfessionalAPI = (data) => {
  return request({
    url: '/addDomain',
    method: 'POST',
    data
  })
}
// 删除专业
export const deleteProfessionalAPI = (params) => {
  return request({
    url: '/domainDel',
    method: 'POST',
    params
  })
}

// 删除层次
export const deleteLevelAPI = (params) => {
  return request({
    url: '/domainlevelDel',
    method: 'POST',
    params
  })
}

// 修改专业
export const modifyTheMajorAPI = (data) => {
  return request({
    url: '/domainSave',
    method: 'POST',
    data
  })
}

// 添加层次
export const addLevelAPI = (data) => {
  return request({
    url: '/addlevelDomain',
    method: 'POST',
    data
  })
}

// 层次详情
export const hierarchicalDetailsAPI = (params) => {
  return request({
    url: '/domainInfo',
    method: 'GET',
    params
  })
}

// 层次修改
export const hierarchicalModificationAPI = (data) => {
  return request({
    url: '/domainlevelSave',
    method: 'POST',
    data
  })
}
//

// 是否启用专业
export const domainStateAPI = (params) => {
  return request({
    url: '/domainState',
    method: 'POST',
    params
  })
}
